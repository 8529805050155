.component {
  color: var(--color);
}

.componentText {
  text-decoration: underline;
}

.icon {
  display: flex;
  align-items: center;
}

.componentMenu {
  line-height: 1;
  white-space: nowrap;
  text-transform: lowercase;
  padding: 16px 0 14px 0;
  display: block;
}

.componentFooter {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-14);
}
