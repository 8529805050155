
.component {
  display: none;

  @media (--viewport-lg) {
    display: flex;
    background-color: var(--color-white);
    border-radius: var(--size-40);
    padding: var(--size-10);
    overflow: hidden;
    pointer-events: auto;
  }

  &.hasMultipleItems {
    @media (--viewport-lg) {
      display: none;
    }

    @media (--viewport-xl) {
      display: flex;
    }
  }

  & > .ticketButton {
    align-self: flex-start;
  }
}

.wrapper {
  display: flex;
}

.button,
.ticketButton {
  font-family: var(--font-family-base);
}
