.component {
  z-index: 0;
  position: relative;
  overflow: hidden;
  border-radius: var(--size-40);
  transition: var(--motion-duration-500) ease;
  height: 100%;

  @media (--viewport-md) {
    border-radius: var(--size-60);
    height: 100vh;
  }

  &.translated {
    @media (--viewport-md) {
      transform: translateX(-112px);
    }
  }

  & > .image {
    width: 100%;
    height: 100vh;
  }

  & > .layout {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: var(--container-max-width-lg);
  }

  & > .animateButton {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.image {
  &.imageDesktop {
    display: none;

    @media (--viewport-md) {
      display: block;
    }
  }

  &.imageMobile {
    display: block;

    @media (--viewport-md) {
      display: none;
    }
  }
}

.layout {
  --padding-h: calc(var(--size-40) / 2);

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  padding: var(--size-30) var(--padding-h) var(--size-80);

  @media (--viewport-lg) {
    --padding-h: calc(var(--size-60) / 2);

    padding: var(--size-70) var(--padding-h);
  }

  @media (--viewport-xl) {
    --padding-h: calc(var(--size-80) / 2);
  }

  & > * {
    @media (--viewport-md) {
      max-width: 600px;
    }
  }

  & > .openingHoursNotification {
    margin-bottom: var(--size-20);
  }

  & > .infoMessage {
    margin-top: var(--size-80);
  }
}

.block {
  background-color: var(--color-orange);
  border-radius: var(--size-40);
  padding: var(--size-20) var(--padding-h);

  @media (--viewport-md) {
    border-radius: var(--size-60);
    padding: var(--size-60) var(--padding-h) var(--size-48) var(--padding-h);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.links {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .link {
    flex-grow: 1;

    &:first-child:last-child {
      flex-grow: 0;
    }
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-18);

    @media (--viewport-md) {
      margin-right: var(--size-18);
      margin-bottom: 0;
    }
  }
}

.componentKiekeboe {
  transform: translate(-55px, -55px);
  position: relative;
  display: none;

  @media (--viewport-md) {
    display: block;
  }

  & > .circle {
    width: 25px;
    height: 25px;
  }

  & > .line {
    width: 18px;
    height: 8px;
    position: absolute;
  }

  &:hover {
    & > .lineOne { transform: translate(-35px, -18px) scaleX(1); }
    & > .lineTwo { transform: translate(-28px, -40px) scale(1) rotate(45deg); }
    & > .lineThree { transform: translate(-6px, -48px) scaleY(1) rotate(90deg); }
  }
}

.circle {
  border: 8px solid var(--color-black);
  background-color: var(--color-white);
  border-radius: 50%;
  transition: transform var(--motion-duration-300) ease;

  &:hover {
    transform: scale(1.5);
    border: 6px  solid var(--color-black);
  }
}

.line {
  background-color: var(--color-black);
  border-radius: 8px;
  transition: transform 0.2s ease;
  transform-origin: right;

  &.lineOne {
    transform: translate(-35px, -18px) scaleX(0);
  }

  &.lineTwo {
    transform: translate(-28px, -40px) scale(0) rotate(45deg);
    transition-delay: 100ms;
  }

  &.lineThree {
    transform: translate(-6px, -48px) scaleY(0) rotate(90deg);
    transition-delay: 200ms;
  }
}

.componentInfoMessage {
  background-color: var(--color-yellow);
  color: var(--color-black);
  font-weight: var(--font-weight-medium);
  padding: 16px var(--size-30) var(--size-20) var(--size-40);
  border-radius: var(--size-60);
  display: none;
  font-size: var(--font-size-16);
  transition: opacity var(--ease-in-out) var(--motion-duration-300);

  @media (--viewport-md) {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  &.hide {
    opacity: 0;
    display: none;
  }

  & > .infoLabel {
    margin-top: 4px;
  }
}

.componentOpeningHoursNotification {
  color: var(--color-black);
  text-transform: lowercase;
  font-size: var(--font-size-16);
  display: inline-flex;
  z-index: 0;
  position: relative;

  & > .viewAllHours {
    z-index: -1;
  }

  &:hover > .viewAllHours {
    opacity: 1;
    transform: translateX(-15%);
    pointer-events: auto;
  }
}

.openToday {
  border-radius: var(--size-40);
  background-color: var(--color-white);
  padding: var(--size-12) var(--size-24);
}

.viewAllHours {
  border-radius: var(--size-40);
  background-color: var(--color-yellow);
  padding: var(--size-12) var(--size-24) var(--size-12) var(--size-48);
  opacity: 0;
  pointer-events: none;
  transform: translateX(-25%);
  transition: transform var(--motion-duration-300), opacity var(--motion-duration-300);
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}
