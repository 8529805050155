.componentMainContent {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);
  color: var(--color);

  & > .heading,
  & > .subheading {
    margin-bottom: var(--size-30);
    margin-top: var(--size-60);

    @media (--viewport-md) {
      margin-top: var(--size-80);
    }
  }

  & > .paragraph {
    margin-top: var(--size-20);
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-top: var(--size-40);
      margin-bottom: var(--size-40);
    }
  }

  & > .quote {
    margin-top: var(--size-30);
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-top: var(--size-60);
      margin-bottom: var(--size-60);
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.componentMainContentSmall {
  font-size: var(--font-size-16);
}

.componentSimple,
.componentSimpleWithLinks {
  & > * {
    margin-bottom: var(--size-20);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.componentFooterContent {
  font-size: var(--font-size-18);
  line-height: 1.5;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.subheading {
  font-size: var(--font-size-18);
  font-weight: bold;
}

.orderedList {
  list-style-type: decimal;
  list-style-position: inside;
}

.unorderedList {
  list-style-type: disc;
  list-style-position: inside;
}

.componentPlaceholder {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: solid 2px #000;
  border-bottom: solid 2px #000;
}
