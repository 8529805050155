.component {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    min-height: 100vh !important;
    min-height: 100lvh !important;
  }

  @media (--viewport-md) {
    flex-direction: row;
  }

  &.reverse {
    @media (--viewport-md) {
      flex-direction: row-reverse;
    }
  }

  & > * {
    width: 100%;

    @media (--viewport-md) {
      flex: 0 0 50%;
    }
  }

  & > .imageSquare {
    align-self: stretch;
  }

  & > .imageCircle {
    bottom: 0;
    align-self: flex-end;
  }
}

.container {
  color: var(--color);
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;

  & > .content {
    width: 100%;
  }

  @media (--viewport-md) {
    & > .content {
      width: 80%;
      max-width: var(--container-max-width-md);
    }
  }
}

.content {
  padding: var(--size-48) var(--size-40);
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);


  @media (--viewport-md) {
    padding: var(--size-24) var(--size-60);
  }

  @media (--viewport-xl) {
    padding: var(--size-40) var(--size-80);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }
}

.image {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentImageSquare {
  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentImageCircle {
  position: sticky;
  overflow: hidden;
  border-radius: 50%;

  & > .imageWrapper {
    width: 100%;
    height: 100%;
    max-width: 100vh;
    max-width: 100lvh;
  }
}

.imageWrapper {
  border-radius: 50%;
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }
}
