.component {
  display: grid;
  gap: var(--size-48);

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  display: flex;
  flex-direction: column;

  & > .heading {
    margin-bottom: var(--size-24);
  }

  & > .content {
    margin-bottom: var(--size-30);
  }

  & > .image {
    order: -1;
    margin-bottom: var(--size-40);
  }

  & > .button {
    align-self: flex-start;
  }
}

.image {
  border-radius: var(--size-60);
}
