
.component {
  position: relative;
  font-family: var(--font-family-alt);
  z-index: 0;
  pointer-events: auto;

  @media (--viewport-lg) {
    display: none;
  }

  &.hasAlotOfItems {
    @media (--viewport-lg) {
      display: flex;
    }

    @media (--viewport-xl) {
      display: none;
    }
  }

  & > .button {
    position: absolute;
    right: var(--size-20);
    top: var(--size-20);
    height: 52px;
    min-width: 135px;
  }

  & > .wrapper {
    width: 100%;
    height: 0;
    z-index: -1;

    &.active {
      height: 100vh;
      height: 100lvh;
    }
  }
}

.button {
  font-family: var(--font-family-base);
}

.wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0;
  opacity: 0;
  transition: opacity var(--motion-duration-300);

  &.active {
    overflow: scroll;
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: var(--color-white);
    z-index: -1;
    width: 100%;
    height: 100%;
    transform-origin: top;
    transform: scaleY(0.6);
    transition: transform var(--motion-duration-300);
  }

  &.active::before {
    position: fixed;
    transform: scaleY(1);
    opacity: 1;
  }

  & > .logoWrapper {
    position: absolute;
    left: -10px;
    top: -40px;
    width: 110px;
  }

  & > .menuMobile {
    min-height: 100%;
  }
}
