.component {
  & > .layout {
    @media (--viewport-md) {
      min-height: 100vh;
      min-height: 100lvh;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  padding: var(--size-120) var(--size-40);
  gap: var(--size-40);

  @media (--viewport-md) {
    flex-direction: row;
    padding: var(--size-20) var(--size-60);
  }

  @media (--viewport-lg) {
    padding: var(--size-90);
  }

  & > * {
    @supports not (gap: var(--size-40)) {
      margin: calc(var(--size-40) / 2);
    }
  }

  & > .content,
  & > .carouselContainer {
    height: 50%;
    flex: 1;

    @media (--viewport-md) {
      height: auto;
      width: 50%;
    }
  }

  & > .content {
    order: 1;

    @media (--viewport-md) {
      order: 0;
    }

    &.reversed {
      @media (--viewport-md) {
        order: 1;
      }
    }
  }
}

.content {
  color: var(--color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (--viewport-md) {
    justify-content: center;
  }

  & > .contentInner {
    max-width: var(--container-max-width-md);
    width: 100%;
  }

  & > .contentButton {
    align-self: flex-start;
  }
}

.contentInner {
  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }
}

.carouselContainer {
  /* padding: var(--size-20) var(--size-40); */
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (--viewport-md) {
    align-items: center;
  }

  @media (--viewport-lg) {
    /* padding: var(--size-90); */
  }

  & > .carousel {
    width: 100%;
    /* max-width: 600px; */

    @media (--viewport-lg) {
      /* max-width: 700px; */
    }
  }
}
