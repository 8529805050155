.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-8);
  }
}

.componentItem {
  overflow: hidden;
  background-color: var(--secondary-background-color);
  color: var(--secondary-color);
  border-radius: var(--size-40);
  box-shadow: inset 0 0 0 5px var(--secondary-background-color);

  &.active {
    box-shadow: inset 0 0 0 5px var(--secondary-color);
  }

  & > .title {
    width: 100%;
  }
}

.title {
  & > .trigger {
    width: 100%;
  }
}

.trigger {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  padding: var(--size-20) var(--size-24);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  @media (--viewport-md) {
    text-align: center;
    padding: 22px var(--size-30);
  }
}

.content {
  overflow: hidden;
}

.icon {
  transition: transform var(--motion-duration-300) var(--ease-in-out);

  &.rotate {
    transform: rotate(180deg);
  }
}

.inner {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  padding: var(--size-12) var(--size-24) var(--size-24) var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-30) var(--size-35) var(--size-30);
  }
}
