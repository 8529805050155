.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);
  color: var(--color);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    gap: var(--size-80);
  }

  & > .content,
  & > .imageContainer {
    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .imageContainer {
    order: -1;
    width: 65%;
    margin: 0 auto;

    @media (--viewport-md) {
      order: -1;
      margin: unset;
    }

    &.right {
      @media (--viewport-md) {
        order: 1;
      }
    }
  }
}

.image {
  border-radius: 50%;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewport-md) {
    justify-content: flex-end;
  }
}

.componentContent {
  & > * {
    margin-top: var(--size-20);
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-top: var(--size-40);
      margin-bottom: var(--size-40);
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
