.component {
  & > .error {
    margin-top: var(--size-8);
  }
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  gap: var(--size-18);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: flex-start;
  }

  & > .button {
    margin-top: calc(var(--font-size-16) + var(--size-8));
  }
}

.error {
  font-family: var(--font-family-base);
  color: var(--color-orange);
}
