.component {
  & > .heading {
    margin-bottom: var(--size-30);
  }

  & > .button {
    width: 100%;
    margin-top: var(--size-8);
  }
}

.items {
  & > :not(:last-child) {
    margin-bottom: 6px;
  }
}

.componentItem {
  overflow: hidden;
  background-color: var(--secondary-background-color);
  color: var(--secondary-color);
  border-radius: var(--size-40);
  border: 5px solid var(--secondary-background-color);

  &.active {
    border: 5px solid var(--secondary-color);
  }

  & > .question {
    width: 100%;
  }
}

.question {
  & > .trigger {
    width: 100%;
  }
}

.trigger {
  font-size: var(--font-size-18-22);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: var(--size-18) var(--size-24) 16px;
  gap: var(--size-10);

  @media (--viewport-md) {
    padding: var(--size-20) var(--size-30) 16px;
  }

  & > .icon {
    flex-shrink: 0;
  }
}

.answer {
  overflow: hidden;
}

.icon {
  transition: transform var(--motion-duration-300) var(--ease-bounce-cubic);

  &.rotate {
    transform: rotate(180deg);
  }
}

.inner {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  padding: var(--size-12) var(--size-24) var(--size-24) var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-30) var(--size-35) var(--size-30);
  }
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  border-radius: var(--size-40);
  border: 5px solid var(--primary-background-color);
  padding: var(--size-12) var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-35);
  }

  & > .icon {
    flex-shrink: 0;
  }
}
