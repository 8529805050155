.component {
  & > .layout {
    @media (--viewport-md) {
      min-height: 100vh;
      min-height: 100lvh;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  padding: var(--size-120) var(--size-40);
  gap: var(--size-70);

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: center;
    padding: var(--size-30) var(--size-60);
    gap: var(--size-120);
  }

  @media (--viewport-xl) {
    padding: var(--size-30) var(--size-80);
  }

  & > * {
    @supports not (gap: var(--size-60)) {
      margin: 0 var(--size-30);

      @media (--viewport-md) {
        margin: 0 var(--size-60);
      }
    }
  }

  & > .quoteAndAuthor {
    margin: 0 auto;
    order: 1;

    @media (--viewport-md) {
      height: auto;
      order: 0;
      width: 50%;
      max-width: 600px;
      margin: 0;
    }

    @media (--viewport-lg) {
      max-width: var(--container-max-width-md);
    }

    &.reversed {
      @media (--viewport-md) {
        order: 1;
      }
    }
  }

  & > .imageContainer {

    @media (--viewport-md) {
      height: auto;
      width: 50%;
      max-width: 600px;
    }

    @media (--viewport-lg) {
      max-width: var(--container-max-width-md);
    }
  }
}

.quoteAndAuthor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewport-md) {
    align-items: flex-start;
  }

  & > .quote,
  & > .author {
    max-width: 600px;
    width: 100%;

    @media (--viewport-lg) {
      max-width: 700px;
    }
  }

  & > .quote {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }
}

.quote {
  font-family: var(--font-family-alt);
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-50);
  text-transform: lowercase;
  position: relative;

  & > .icon {
    position: absolute;
    width: var(--size-60);
    top: -80px;
    left: -20px;

    @media (--viewport-md) {
      width: var(--size-80);
      top: -100px;
      left: -30px;
    }
  }
}

.author {
  font-size: var(--font-size-20);
}

.icon {
  color: var(--secondary-background-color);
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (--viewport-md) {
    align-items: center;
  }

  & > .image {
    max-width: 300px;

    @media (--viewport-md) {
      width: 100%;
      max-width: 600px;
    }

    @media (--viewport-lg) {
      max-width: 700px;
    }
  }
}

.image {
  border-radius: 50%;
}
