
.componentBase,
.componentABase,
.componentAnimatedButtonBase,
.componentAnimatedABase {
  display: inline-flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  transition: color var(--motion-duration-150) ease, background-color var(--motion-duration-150) ease;
  position: relative;
  z-index: 0;

  & > .baseInner {
    width: 100%;
  }

  & > .backgroundAnimation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.componentPrimary,
.componentSecondary,
.componentBlack,
.componentWhite,
.componentAnimated {
  padding: 16px var(--size-40) 14px;
  border-radius: var(--size-40);
  position: relative;
  overflow: hidden;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-alt);
  text-transform: lowercase;
  z-index: 0;
}

.componentPrimary {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  position: relative;
  overflow: hidden;

  &:hover {
    color: var(--primary-color-hover);
  }

  &:matches(.componentAnimatedABase, .componentAnimatedButtonBase) {
    &:hover {
      background-color: var(--primary-background-color);
    }
  }
}

.componentAnimated {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  position: relative;
  overflow: hidden;

  &:hover {
    color: var(--primary-color-hover);
  }
}

.backgroundAnimation {
  background-color: var(--primary-background-color-hover);
  border-radius: inherit;
  pointer-events: none;
}

.componentSecondary {
  background-color: var(--secondary-background-color);
  color: var(--secondary-color);

  &:hover {
    background-color: var(--secondary-background-color-hover);
    color: var(--secondary-color-hover);
  }

  &:matches(.componentAnimatedABase, .componentAnimatedButtonBase) {
    &:hover {
      background-color: var(--primary-background-color);
    }
  }

  & > .backgroundAnimation {
    background-color: var(--secondary-background-color-hover);
  }
}

.componentBlack {
  background-color: var(--color-black);
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-white);

    @media (--viewport-md) {
      color: var(--color-black);
    }
  }

  &:matches(.componentAnimatedABase, .componentAnimatedButtonBase) {
    &:hover {
      background-color: var(--color-black);
    }
  }

  & > .backgroundAnimation {
    background-color: var(--color-white);
  }
}

.componentWhite {
  background-color: var(--color-white);
  color: var(--color-black);

  &:hover {
    color: var(--color-black);

    @media (--viewport-md) {
      color: var(--color-white);
    }
  }

  & > .backgroundAnimation {
    background-color: var(--color-black);
  }
}

.componentFatChevronRightPrimary {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  padding: var(--size-18) var(--size-24);
  border-radius: var(--size-40);

  @media (--viewport-md) {
    padding: var(--size-18) var(--size-35);
  }
}

.componentArrowLeft,
.componentArrowRight {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-background-color);
  color: var(--secondary-color);
  width: 60px !important;
  height: 60px !important;
  padding: 0;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &:hover {
    color: var(--secondary-color-hover);
  }

  & > .backgroundAnimation {
    background-color: var(--color-black);
  }
}

.inner {
  pointer-events: none;
}

.baseInner {
  line-height: 1;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
