.componentBase {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;

  @media (--viewport-md) {
    --padding: 5vw;
  }

  & > .carousel {
    width: 100%;
    z-index: 0;
  }

  & > .previous {
    position: absolute;
    z-index: 1;
    left: 5vw;

    @media (--viewport-md) {
      left: calc(2.5vw + var(--size-24));
    }
  }

  & > .next {
    position: absolute;
    z-index: 1;
    right: 5vw;

    @media (--viewport-md) {
      right: calc(2.5vw + var(--size-24));
    }
  }
}

.carousel {
  --border-radius: 40px;

  display: grid;
  touch-action: none;
  user-select: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media (--viewport-md) {
    --border-radius: 60px;
  }

  & > .slide {
    width: 100%;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }
}

.slide {
  will-change: transform;
  border-radius: var(--border-radius);
  overflow: hidden;

  & > * {
    width: 100%;
  }
}

.imageDesktop {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.imageMobile {
  display: block;

  @media (--viewport-md) {
    display: none;
  }
}
