.component {
  position: relative;
  padding: var(--size-24);
  box-sizing: content-box;
  width: 100px !important;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-white);

  @media (--viewport-md) {
    align-items: flex-start;
    padding: var(--size-40);
    width: 155px !important;
    height: 155px !important;
  }
 
  & > * {
    position: relative;
    bottom: unset;
    top: 5%;
    width: 100%;
    height: auto;

    @media (--viewport-md) {
      bottom: 30%;
      top: unset;
    }
  } 

  & > .copyright {
    position: absolute;
    left: 15px;
    top: 106px;
    font-size: 3px;

    @media (--viewport-md) {
      top: 185px;
      left: 35px;
    }
  }
}

