.component {
  position: relative;
  z-index: 0;
  display: grid;
  background-color: var(--background-color);
  color: var(--color);
  font-family: var(--font-family-alt);
  align-items: center;
  padding: var(--size-48) var(--size-40);

  @media (--viewport-md) {
    padding: var(--size-24) var(--size-60);
  }

  @media (--viewport-xl) {
    padding: var(--size-40) var(--size-80);
  }

  & > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }

  & > .newsletterForm {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.newsletterForm {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;

  & > .form {
    min-height: 102px;
  }

  & > .close {
    position: absolute;
    right: 0;
    top: calc(-1 * var(--size-12));

    @media (--viewport-md) {
      top: auto;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  @media (--viewport-md) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  & > :not(:last-child) {
    @supports not (gap: 1px) {
      margin-bottom: var(--size-48);
    }
  }

  @media (--viewport-sm) {
    flex-direction: row;
    gap: var(--size-48);
  }
}

.componentContent {
  & > :not(:last-child) {
    margin-bottom: var(--size-8);
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  @media (--viewport-xl) {
    flex-direction: row;
  }

  & > .button {
    margin-top: var(--size-24);
    margin-bottom: var(--size-20);
    width: 100%;
  }

  & > .socialIcons {
    @media (--viewport-lg) {
      margin-left: var(--size-24);
    }
  }
}

.socialIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-18);

  @media (--viewport-sm) {
    justify-content: flex-start;
  }

  @media (--viewport-md) {
    justify-content: flex-end;
  }

  & > .icon {
    width: 50px;
    height: 50px;
  }
}

.icon {
  background-color: var(--secondary-background-color);
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.contentRight {
  display: flex;
  flex-direction: column;
}

.copyrightText {
  text-align: right;
  margin-top: var(--size-24);
  font-size: var(--font-size-10);
}
