.component {
  & > .layout {
    min-height: 100vh;
    min-height: 100lvh;
  }
}

.layout {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    height: 50%;
    flex: 1 0 50vh;
    flex: 1 0 50lvh;

    @media (--viewport-md) {
      height: auto;
      width: 50%;
      flex: 0 0 auto;
    }
  }
}

.map {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5ead5;

  & > .image {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  & > .marker {
    position: absolute;
  }
}

.image {
  object-fit: cover;
  object-position: center;
}

.marker {
  transform: scale(0.66) translateY(-29%);

  @media (--viewport-md) {
    transform: translateY(-29%);
  }
}

.content {
  padding: 140px var(--size-60) var(--size-60);
  min-height: 400px !important;
  background-color: var(--background-color);
  color: var(--color);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (--viewport-md) {
    padding-top: var(--size-60);
    justify-content: center;
  }

  @media (--viewport-lg) {
    padding: var(--size-90);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }

  & > .accordion {
    margin-bottom: var(--size-8);
  }

  & > .title,
  & > .contentText,
  & > .button {
    max-width: var(--container-max-width-md);
    width: 100%;
  }

  & > .contentButton {
    align-self: flex-start;
  }
}
