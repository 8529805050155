.component {
  font-size: var(--font-size-16);
  font-family: var(--font-family-base);
  color: var(--color);

  & > .paragraph {
    margin-top: var(--size-20);
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-top: var(--size-40);
      margin-bottom: var(--size-40);
    }
  }

  & * {
    font-family: inherit;
  }

  & :global(.CookieDeclarationTypeHeader) {
    font-weight: var(--font-weight-bold);
  }

  & :global(table) * {
    border-color: transparent;
  }

  & :global(.CookieDeclarationType) {
    border-color: transparent;
    background-color: var(--color-white);
    border-radius: var(--size-60);
    box-shadow:
      inset 0 0 0 1px var(--color-white),
      inset 0 0 0 1px var(--color-white),
      inset 0 0 0 1px var(--color-white),
      inset 0 0 0 var(--size-10) var(--color-white),
      inset 0 0 0 var(--size-18) var(--color-black);
    padding: var(--size-40);

    @media (--viewport-md) {
      border-radius: var(--size-48);
      padding: var(--size-40) var(--size-60) var(--size-60) var(--size-60);
    }
  }
}
