.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

.link {
  display: flex;
  align-items: baseline;

  & > .input {
    width: var(--size-18);
    height: var(--size-18);
    margin-right: var(--size-18);
    flex-shrink: 0;
  }
}

.input {
  appearance: none;
  background-color: #fff;
  border: 2.5px solid var(--color-black);
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &:focus {
    outline: max(2px, 0.15em) solid var(--color-green);
    outline-offset: max(2px, 0.15em);
  }

  &::before {
    content: "";
    width: var(--size-10);
    height: var(--size-10);
    border-radius: 50%;
    transform: scale(0);
    box-shadow: inset 1em 1em var(--color-green);
  }

  &.checked {
    &::before {
      transform: scale(1);
    }
  }
}
