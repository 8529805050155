.componentBase {
  color: var(--color);
}

.componentXl {
  font-size: var(--font-size-50);
  font-size: var(--font-size-50-100);
}

.componentLg {
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-50);
}

.componentMd {
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-40);
  line-height: 1.3;
}

.componentSm {
  font-size: var(--font-size-30);
}

.title {
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-bold);
  text-transform: lowercase;
}
