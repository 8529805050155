.component {
  color: var(--color);
}

.layout {
  & > .heading {
    margin: var(--size-8) 0 var(--size-24) 0;

    @media (--viewport-md) {
      margin: var(--size-24) 0 var(--size-40) 0;
    }
  }

  & > .listItems {
    margin-bottom: var(--size-24);
  }
}

.listItems {
  font-size: var(--font-size-20);

  @media (--viewport-md) {
    font-size: var(--font-size-25);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-18);
  }
}

.listItem {
  display: flex;
  align-items: center;

  & > .icon {
    margin-right: var(--size-20);
    width: var(--size-24);
    flex-shrink: 0;

    @media (--viewport-md) {
      margin-right: var(--size-30);
      width: var(--size-24);
    }
  }
}
