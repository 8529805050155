.componentBase {
  line-height: 1;
  padding: 14px var(--size-20) 14px var(--size-8);
  padding-left: var(--size-8);
  text-indent: calc(var(--size-20) - var(--size-8));
  border-radius: var(--size-40);
  background-color: var(--color-white);
  color: var(--color-white-contrast);
  box-shadow: inset 0 0 0 2px var(--color-yellow);

  &.invalid {
    box-shadow: inset 0 0 0 2px var(--color-orange);
  }
}
