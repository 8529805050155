.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  & > .validation,
  & > .label {
    margin-left: var(--size-8);
  }
}

.label {
  font-size: var(--font-size-16);
  line-height: 1;
}

.validation {
  font-size: var(--font-size-16);
  font-family: var(--font-family-base);
  color: var(--color-orange);
}
