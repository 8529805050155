.component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 200px var(--size-48) 100px var(--size-48);
  font-size: var(--font-size-25);
  opacity: 0;
  transition: opacity var(--motion-duration-300);
  transition-delay: var(--motion-duration-300);
  gap: var(--size-10);

  @media (--viewport-lg) {
    flex-direction: row;
    opacity: 1;
    font-size: var(--font-size-18);
    gap: var(--size-60);
    text-transform: lowercase;
    transition: width var(--motion-duration-300);
    padding: 0 var(--size-60) 0 var(--size-40);
  }

  &.active {
    opacity: 1;
  }

  & > * {
    @supports not (gap: var(--size-60)) {
      margin: 0 var(--size-10);

      @media (--viewport-lg) {
        margin: 0 var(--size-30);
      }
    }
  }

  & > .ticketLink {
    margin-top: auto;
  }
}
