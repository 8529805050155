.component {
  background-color: var(--color-white);
  border-radius: var(--size-60);
  padding: var(--size-35);
  border: var(--size-10) solid var(--color-white);
  box-shadow: inset 0 0 0 var(--size-8) var(--color-black);

  @media (--viewport-md) {
    border-radius: var(--size-48);
    padding: var(--size-40) var(--size-60) var(--size-60) var(--size-60);
  }
}
