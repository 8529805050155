.component {
  & > .layout {
    min-height: 100vh;
    min-height: 100lvh;
  }
}

.layout {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    flex-direction: row;
  }

  &.reversed {
    @media (--viewport-md) {
      flex-direction: row-reverse;
    }
  }

  & > * {
    height: 50%;
    flex: 1;

    @media (--viewport-md) {
      height: auto;
      width: 50%;
    }
  }
}

.content {
  background: var(--background-color);
  color: var(--color);
  padding: var(--size-20) var(--size-30);
  display: flex;
  flex-direction: column;
  border-radius: var(--size-40);
  justify-content: center;
  align-items: center;

  @media (--viewport-md) {
    justify-content: center;
    padding: var(--size-90);
    border-radius: var(--size-80);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }

  & > .title,
  & > .contentText,
  & > .button {
    max-width: var(--container-max-width-md);
    width: 100%;
  }

  & > .contentButton {
    align-self: flex-start;
  }
}

.imageWithLinks {
  padding: var(--size-40) var(--size-30);
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: var(--size-40);
  overflow: hidden;
  align-items: center;

  @media (--viewport-md) {
    padding: var(--size-90);
    border-radius: var(--size-80);
  }

  & > .image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  & > .links {
    max-width: var(--container-max-width-md);
    width: 100%;
  }
}

.componentAgenda {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  padding: var(--size-18);
  border-radius: var(--size-30);

  @media (--viewport-md) {
    padding: var(--size-30);
  }
}

.componentAgendaItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > .linkInner {
    flex-grow: 1;

    &.relativeToParent {
      position: static;
    }
  }

  &:not(:first-child) {
    padding-top: var(--size-12);

    @media (--viewport-md) {
      padding-top: var(--size-24);
    }
  }

  &:not(:last-child) {
    padding-bottom: var(--size-10);
    border-bottom: 1px solid;

    @media (--viewport-md) {
      padding-bottom: var(--size-20);
    }
  }

  & > .iconWrapper {
    flex-shrink: 0;
  }

  &:hover > .iconWrapper {
    transform: translateX(-12px);
  }
}

.linkInner {
  display: flex;
  flex-direction: column;
  position: relative;

  & > .link.relativeToParent {
    position: static;
  }

  & > .label {
    margin-bottom: 4px;
  }
}

.link {
  font-family: var(--font-family-alt);
  font-size: var(--font-size-20);
  text-transform: lowercase;
  position: relative;

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.label {
  font-size: var(--font-size-18);
  text-transform: lowercase;
}

.contentText {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);
}

.icon {
  display: flex;
}

.iconWrapper {
  transition: transform var(--motion-duration-300);

  & > .icon {
    width: var(--size-18);
    height: var(--size-18);

    @media (--viewport-md) {
      width: var(--size-24);
      height: var(--size-24);
    }
  }
}
