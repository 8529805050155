.component {
  --border-radius: 40px;

  display: grid;
  grid-template-rows: auto minmax(10px, 1fr) auto;
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    --border-radius: 60px;
  }

  & > * {
    min-width: 0;
  }

  & > .top {
    z-index: 1;
    top: 0;
    width: 100%;
    grid-column: 1 / -1;
    grid-row: 1;
  }

  & > .bottom {
    z-index: 1;
    bottom: 0;
    width: 100%;
    grid-column: 1 / -1;
    grid-row: 3;
  }

  & > .content {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}

.top,
.bottom {
  position: sticky;
  display: flex;
  justify-content: space-between;
  contain: layout;
  pointer-events: none;
}

.top {
  --corner-top-left: path('M0,40 C0,20 20,0 40,0 C20,0 20,0 0,0 0,20 0,20 0,40 Z');
  --corner-top-right: path('M0,0 C20,0 40,20 40,40 C40,40 40,20 40,0 C20,0 0,0 0,0 Z');

  @media (--viewport-md) {
    --corner-top-left: path('M0,60 C0,30 30,0 60,0 C30,0 30,0 0,0 0,30 0,30 0,60 Z');
    --corner-top-right: path('M0,0 C30,0 60,30 60,60 C60,60 60,30 60,0 C30,0 0,0 0,0 Z');
  }

  &::before,
  &::after {
    content: '';
    display: block;
    background-color: var(--site-background-color);
    width: var(--border-radius);
    height: calc(2 * var(--border-radius));
  }

  &::before {
    left: 0;
    top: 0;
    bottom: 0;
    clip-path: var(--corner-top-left);
  }

  &::after {
    right: 0;
    top: 0;
    bottom: 0;
    clip-path: var(--corner-top-right);
  }
}

.bottom {
  --corner-bottom-right: path('M0,80 C20,80 40,60 40,40 C40,40 40,60 40,80 C20,80 0,80 0,80 Z');
  --corner-bottom-left: path('M0,40 C0,60 20,80 40,80 C40,80 20,80 0,80 C0,60 0,40 0,40 Z');

  @media (--viewport-md) {
    --corner-bottom-right: path('M0,120 C30,120 60,90 60,60 C60,60 60,90 60,120 C30,120 0,120 0,120 Z');
    --corner-bottom-left: path('M0,60 C0,90 30,120 60,120 C60,120 30,120 0,120 C0,90 0,60 0,60 Z');
  }

  &::before,
  &::after {
    content: '';
    display: block;
    background-color: var(--site-background-color);
    width: var(--border-radius);
    height: calc(2 * var(--border-radius));
  }

  &::before {
    left: 0;
    bottom: 0;
    clip-path: var(--corner-bottom-left);
  }

  &::after {
    right: 0;
    bottom: 0;
    clip-path: var(--corner-bottom-right);
  }
}

.content {
  border-radius: var(--border-radius);
  overflow: hidden;

  & > * {
    height: 100%;
    min-width: 100%;
  }
}
