.componentSection {
  padding: var(--size-40) var(--size-30);
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: var(--size-40);
  overflow: hidden;
  align-items: center;
  background-color: var(--color-yellow);

  & > .activities {
    width: 100%;
    margin: 0 auto;

    @media (--viewport-md) {
      width: var(--container-max-width-md);
    }
  }

  @media (--viewport-md) {
    padding: var(--size-90) 0;
    border-radius: var(--size-80);
  }
}

.activities {
  display: flex;
  flex-direction: column;
}

.linkSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > .linkInner {
    flex-grow: 1;
    &.relativeToParent {
      position: static;
    }
  }

  &:not(:first-child) {
    padding-top: var(--size-12);

    @media (--viewport-md) {
      padding-top: var(--size-24);
    }
  }

  &:not(:last-child) {
    padding-bottom: var(--size-10);
    border-bottom: 1px solid;

    @media (--viewport-md) {
      padding-bottom: var(--size-20);
    }
  }

  & > .iconWrapper {
    flex-shrink: 0;
  }

  &:hover > .iconWrapper {
    transform: translateX(-12px);
  }
}

.linkInner {
  display: flex;
  flex-direction: column;
  position: relative;

  & > .link.relativeToParent {
    position: static;
  }

  & > .label {
    margin-bottom: 4px;
  }
}

.link {
  font-family: var(--font-family-alt);
  font-size: var(--font-size-20);
  text-transform: lowercase;
  position: relative;

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.label {
  font-size: var(--font-size-18);
  text-transform: lowercase;
}

.contentText {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);
}

.icon {
  display: flex;
}

.iconWrapper {
  transition: transform var(--motion-duration-300);

  & > .icon {
    width: var(--size-18);
    height: var(--size-18);

    @media (--viewport-md) {
      width: var(--size-24);
      height: var(--size-24);
    }
  }
}

