.imageTitle {
  color: var(--color-white);
}

.componentColor {
  padding-bottom: var(--size-40);
  background-color: var(--background-color);
  color: var(--color);

  @media (--viewport-md) {
    padding-bottom: var(--size-80);
  }

  & > .layout {
    min-height: 360px;

    @media (--viewport-md) {
      min-height: 470px;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 0;

  & > .dateText {
    width: auto;
  }

  & > .videoClickableArea {
    width: 100%;
    height: calc(100% + var(--size-40));
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }
}

.componentPlayIcon {
  &:not([disabled]) {
    cursor: none;
  }
}

.videoClickableArea {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: var(--size-30) var(--size-30) var(--size-40);
  position: relative;

  & > .animatedButton {
    position: relative;
    left: 0;
    top: 0;
    width: var(--size-80);
    height: var(--size-80);

    @media (--viewport-md) {
      position: absolute;
      width: var(--size-100);
      height: var(--size-100);
    }
  }
}

.componentImage {
  padding-bottom: var(--size-40);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding-bottom: var(--size-80);
  }

  & > .coverImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  & > .layout {
    min-height: 360px;

    @media (--viewport-md) {
      min-height: 550px;
    }
  }
}

.coverImage {
  position: relative;
  z-index: 0;

  & > .image {
    width: 100%;
    height: 100%;
  }

  &::after {
    border-radius: 0 0 var(--size-40) var(--size-40);
    position: absolute;
    content: '';
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);

    @media (--viewport-md) {
      border-radius: 0 0 var(--size-80) var(--size-80);
    }
  }
}

.image {
  border-radius: 0 0 var(--size-40) var(--size-40);

  @media (--viewport-md) {
    border-radius: 0 0 var(--size-80) var(--size-80);
  }
}

.componentVideo {
  padding-bottom: var(--size-40);
  border-radius: 0 0 var(--size-40) var(--size-40);
  position: relative;
  z-index: 0;
  cursor: none;

  @media (--viewport-md) {
    padding-bottom: var(--size-80);
    border-radius: 0 0 var(--size-80) var(--size-80);
  }

  & > .layout {
    min-height: 80vh;
  }

  & > .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  & > .coverImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.videoTitle {
  color: var(--color-white);
  opacity: 1;
  transition: opacity var(--motion-duration-300) var(--ease-in-out);

  &.hide {
    opacity: 0;
  }
}

.video {
  border-radius: 0 0 var(--size-40) var(--size-40);
  opacity: 0;
  transition: opacity var(--motion-duration-300) var(--ease-in-out);

  @media (--viewport-md) {
    border-radius: 0 0 var(--size-80) var(--size-80);
  }

  &.active {
    opacity: 1;
  }
}

.componentDateBlock {
  background-color: var(--color-white);
  color: var(--color-black);
  padding: var(--size-10) var(--size-20);
  border-radius: var(--size-20);
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-20);
  text-transform: lowercase;
  font-family: var(--font-family-base);
}

.dateText {
  background-color: var(--color-white);
  color: var(--color-black);
  padding: var(--size-10) var(--size-20);
  border-radius: var(--size-20);
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-20);
  text-transform: lowercase;
  font-family: var(--font-family-base);
  display: inline-block;
}

.componentDates {
  display: flex;
  flex-direction: row;
  gap: var(--size-10);
  flex-wrap: wrap;
}

.headingAndDates {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-10);
}

.imageTitle,
.colorTitle,
.videoTitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
