.component {
  position: relative;
  z-index: 0;

  & > .layout {
    position: fixed;
    width: 100%;
  }
}

.layout {
  display: flex;
  justify-content: flex-end;
  padding: var(--size-30);
  position: relative;

  & > .logoWrapper {
    position: absolute;
    left: -15px;
    top: -30px;

    @media (--viewport-md) {
      left: -20px;
      top: -80px;
    }
  }
}

.logoWrapper {
  transform-origin: top left;
  transform: translate(0, 0) scale(0);
  pointer-events: auto;

  &.active {
    transform: translate(-10px, -40px) scale(1);

    @media (--viewport-md) {
      transform: translate(-5px, -85px) scale(1);
    }
  }
}
