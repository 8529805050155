.component {
  color: var(--color);
}

.layout {
  & > .heading {
    margin: var(--size-8) 0 var(--size-24) 0;

    @media (--viewport-md) {
      margin: var(--size-24) 0 var(--size-40) 0;
    }
  }

  & > .tableItems {
    margin-bottom: var(--size-24);
  }

  & > .information {
    margin-bottom: var(--size-30);
  }

  & > .button {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.tableItems {
  font-size: var(--font-size-16);

  @media (--viewport-md) {
    font-size: var(--font-size-22);
  }
}

.tableItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-black);
  padding: var(--size-12) 0;

  @media (--viewport-md) {
    padding: var(--size-20) 0;
  }

  &:first-child {
    @media (--viewport-md) {
      border-top: 1px solid var(--color-black);
    }
  }
}

.value {
  font-weight: var(--font-weight-bold);
}
