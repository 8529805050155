.component {
  position: relative;
  padding-left: var(--size-20);
  color: var(--accent-color);
  font-size: var(--font-size-18);

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    background-color: currentColor;
    border-radius: var(--size-8);
    left: 0;
    top: -0.2em;
    bottom: 0;
  }
}
