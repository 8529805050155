
.componentBase {
  position: relative;
  overflow: hidden;

  @media (--viewport-md) {
    overflow: visible;
  }

  & > .wrapper {
    @media (--viewport-md) {
      max-width: var(--size-48);
    }
  }

  & > .button {
    width: 100%;
  }
}

.button {
  line-height: 1;
  text-transform: lowercase;
  white-space: nowrap;
  text-align: left;
  padding: 16px 0 14px 0;
  display: flex;
  align-items: center;

  & > .icon {
    width: 14px;
    margin: 0 0 2px var(--size-10);

    @media (--viewport-md) {
      width: var(--size-12);
      margin: 0 0 2px 6px;
    }
  }
}

.icon {
  transition: transform var(--motion-duration-300) var(--ease-in-out);

  &.rotate {
    transform: rotate(180deg);
  }
}

.wrapper {
  transition: opacity var(--motion-duration-300);
}

.component,
.componentLanguage {
  white-space: nowrap;
  padding: var(--size-48) 0;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.listItem {
  display: block;
}

.componentLink {
  line-height: 1;
  display: inline-block;
  font-size: var(--font-size-20);

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }

  &:hover > .linkInner {
    transform: translateX(var(--size-20));

    &::before {
      opacity: 1;
    }
  }
}

.linkInner {
  position: relative;
  display: block;
  transform: translateX(0);
  transition: transform 0.3s ease;
  text-transform: lowercase;
  pointer-events: none;

  &::before {
    position: absolute;
    content: '\25CF';
    left: -20px;
    top: 0;
    opacity: 0;
  }
}
