.page {
  & > .title {
    margin-top: var(--size-40);
    margin-bottom: var(--size-18);
  }
}

.title {
  font-size: var(--font-size-40);
  font-weight: bold;
}

.body {
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
  background-color: var(--background-color);

  @media (--viewport-md) {
    padding-top: var(--size-80);
    padding-bottom: var(--size-80);
  }

  @media (--viewport-xl) {
    padding-top: var(--size-120);
    padding-bottom: var(--size-120);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }
}

.componentContent {
  & > * {
    margin: var(--size-70) 0;
  }

  & > .cta {
    margin-top: var(--size-80);
    margin-bottom: var(--size-80);

    @media (--viewport-xl) {
      margin-top: var(--size-120);
      margin-bottom: var(--size-120);
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
