.page {
  & > .hero {
    min-height: 100vh;
  }
}

.content {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }
}
