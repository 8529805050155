.page {
  background-color: var(--color-background);
  color: var(--color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout {
  & > .heading {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .text {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-12);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    @supports not (gap: var(--size-12)) {
      margin: calc(var(--size-12) / 2);
    }
  }
}
