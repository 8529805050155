.component {
  background: var(--background-color);
  color: var(--color);
  padding: var(--size-30);
  border-radius: var(--size-60);
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: var(--font-family-alt);

  @media (--viewport-md) {
    flex-direction: row;
    padding: var(--size-60) var(--size-80);
  }

  & > .image {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    margin-bottom: var(--size-18);
    order: -1;

    @media (--viewport-md) {
      margin-right: var(--size-60);
      margin-bottom: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.heading {
  font-size: var(--font-size-30);
}

.image {
  border-radius: 50%;
  overflow: hidden;

  & > * {
    height: 100%;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: baseline;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-18);

    @media (--viewport-md) {
      margin-right: var(--size-18);
    }
  }
}
